// store/slices/productSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getProducts } from 'services/api';
import { signOut } from './authSlice';

export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
	console.log('Fetching products...');
  const response = await getProducts();
  console.log("Fetched products:", response);
  return response;
});


const productSlice = createSlice({
  name: 'products',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  
  reducers: {
    reset: (state) => {
      console.log("Resetting product state...");
      return {
        items: [],
        loading: false,
        error: null,
      };
    },
  },
    extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
        console.log("State after fetchProducts fulfilled:", state.items);
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.error("Product fetch error:", action.error.message); // Log fetch error
      })
      .addCase(signOut.fulfilled, (state) => {
        console.log("Clearing product state on sign out...");
        return {
          items: [],
          loading: false,
          error: null,
        };
      });
  },
});

// Export the reset action
export const { reset } = productSlice.actions;

// Export the reducer as the default export
export default productSlice.reducer;
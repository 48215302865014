import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = async function (data) {
	try {
		const response = await fetch({
			url: 'https://itframe.unmutedte.ch/authenticate',
			method: 'post',
			data: data
		});
		console.log("AuthService login response:", response); // Log entire response
		return response; // Ensure response structure is as expected
	} catch (error) {
		console.error("AuthService login error:", error); // Log any error that might occur
		throw error;
	}
};

// Admin user login
AuthService.adminLogin = async function (data) {
	try {
		const response = await fetch({
			url: 'https://itframe.unmutedte.ch/admin/authenticate',
			method: 'post',
			data: data
		});
		console.log("AuthService admin login response:", response);
		return response;
	} catch (error) {
		console.error("AuthService admin login error:", error);
		throw error;
	}
};


AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}

AuthService.logout = async function () {
    try {
        localStorage.removeItem('AUTH_TOKEN');
        console.log("User successfully logged out");
    } catch (error) {
        console.error("AuthService logout error:", error);
        throw error; 
    }
};

export default AuthService;
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
import './lang'
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'store';
import * as Sentry from "@sentry/react";

// Initialize Sentry at the top level
Sentry.init({
  dsn: "https://d6bcd6acbeb2412b5457ac9d1d461716@o1084827.ingest.us.sentry.io/4508403951009792",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  sampleRate: 1.0,
});

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

// Wrap the app content with Sentry's Error Boundary
const AppContent = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

function App() {
  return (
    <div className="App">
      <Sentry.ErrorBoundary
        fallback={({ error }) => (
          <div className="error-boundary" style={{
            padding: '20px',
            textAlign: 'center',
            backgroundColor: '#f8d7da',
            color: '#721c24',
            borderRadius: '4px',
            margin: '20px'
          }}>
            <h2>An error has occurred</h2>
            <p>Our team has been notified. Please try again later.</p>
            {process.env.NODE_ENV === 'development' && (
              <details style={{ whiteSpace: 'pre-wrap' }}>
                {error.toString()}
              </details>
            )}
          </div>
        )}
      >
        <AppContent />
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default Sentry.withProfiler(App);
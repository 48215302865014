// store/slices/alexaSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAlexaProducts } from 'services/api';
import { signOut } from './authSlice'; 


export const fetchAlexaProducts = createAsyncThunk('alexa/fetchAlexaProducts', async () => {
  const response = await getAlexaProducts();
  return response;  
});

const alexaSlice = createSlice({
  name: 'alexa',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {
    reset: (state) => {
      console.log("Resetting alexa state...");
      return {
        items: [],
        loading: false,
        error: null,
      };
    },
  },
    extraReducers: (builder) => {
    builder
      .addCase(fetchAlexaProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAlexaProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchAlexaProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(signOut.fulfilled, (state) => {
        console.log("Clearing onePage state on sign out...");
        return {
          items: [],
          loading: false,
          error: null,
        };
      });
  },
});

// Export the reset action
export const { reset } = alexaSlice.actions;

export default alexaSlice.reducer;
